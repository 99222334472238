$(function () {
  $('.js-accordion').click(function(){
    $(this).toggleClass('is-active')
    $(this).next('dd').stop().slideToggle()
  })
  
  new Swiper('.js-functions_slider', {
    spaceBetween: 50,
    slidesPerView: 1,
    speed: 300,
    pagination: {
      el: document.querySelector('.js-functions_slider .swiper-pagination'),
      clickable: true,
    },
		navigation: {
			nextEl: ".js-functions_slider .swiper-button-next",
			prevEl: ".js-functions_slider .swiper-button-prev",
		},
    allowTouchMove: true,
    centeredSlides: true,
    breakpoints: {
      600: {
        spaceBetween: 0,
        allowTouchMove: false,
        centeredSlides: false,
      }
    }
  });
  new Swiper('.js-flow_slider', {
    spaceBetween: 53,
    slidesPerView: 'auto',
    speed: 300,
    pagination: {
      el: document.querySelector('.js-flow_slider .swiper-pagination'),
      clickable: true,
    },
    allowTouchMove: true,
    centeredSlides: true,
    breakpoints: {
      600: {
        spaceBetween: 0,
        allowTouchMove: false,
        centeredSlides: false,
      }
    }
  });
})
